import React, { useEffect, useRef, useState } from "react"
import { IconButton, Tooltip } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, DisabledByDefault, Mic, PlayCircle, SkipNext, Stop, TaskAlt } from '@mui/icons-material';
import parse from 'html-react-parser';

import fetchData from "../services/error-corrector-api"
import { addMimicUser } from "../services/error-corrector-api"
import useRecorder from './useRecorder';

// props: message, index, addCorrectionToChats, showTranscriptMode, allCorrectionMode, handle401Response,
//   updateCorrectStateOfChats, searchAndUpdateSkippedStateOfChats, showCheckbox, showRecording, showTranscript, humanCorrector, addCorrection,
//   canAddCorrection, correctionSuggestion, corrector, autoplayLatest, userToMimic
export function ChatMessage(props) {

  const scrollRef1 = useRef(null);
  const [trigger1, setTrigger1] = useState(true);

  useEffect(() => {
    if (scrollRef1.current) {
      scrollRef1.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [trigger1]);

  return (
    <div>
      <MessageText message={props.message} messageId={props.message.id} transcript={props.message.text}
        displayText={props.message.displayText} reference={props.message.reference} messageCorrect={props.message.correct}
        audioAvailable={props.message.audioAvailable} provided={props.message.provided} index={props.index}
        addCorrectionToChats={props.addCorrectionToChats} scrollTrigger={() => {setTrigger1(!trigger1)}}
        handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats}
        searchAndUpdateSkippedStateOfChats={props.searchAndUpdateSkippedStateOfChats} showCheckbox={props.showCheckbox}
        showRecording={props.showRecording} humanCorrector={props.humanCorrector} skipped={props.message.skipped}
        userToMimic={props.userToMimic}

      />

      {!props.allCorrectionMode && props.showTranscriptMode &&
        <div>
          {props.message.corrections.map((correction, index) => {
            if (index != props.message.corrections.length - 1) {
              return (
                <CorrectionTranscript transcript={correction.transcript}
                  correctionId={correction.id} handle401Response={props.handle401Response}
                  showTranscript={props.showTranscript} userToMimic={props.userToMimic}
                  datetime={correction.datetime}
                />
              )
            } else {
              return (
                <div>
                <li ref={scrollRef1} />
                <CorrectionTranscript transcript={correction.transcript}
                  correctionId={correction.id} handle401Response={props.handle401Response}
                  showTranscript={props.showTranscript} autoplay={props.autoplayLatest} userToMimic={props.userToMimic}
                  datetime={correction.datetime}
                />
                </div>
              )
            }
          })}
        </div>
      }

      {!props.allCorrectionMode && props.message.corrections.at(-1) != null &&
        <div>
          {!props.showTranscriptMode &&
            <li ref={scrollRef1} />
          }
          <CorrectionText correctionText={props.message.corrections.at(-1).correction}
            correctionId={props.message.corrections.at(-1).id} correctionCorrect={props.message.corrections.at(-1).correct}
            handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} showCheckbox={props.showCheckbox}
            addCorrection={props.addCorrection} canAddCorrection={props.canAddCorrection} correctionSuggestion={props.correctionSuggestion}
            provided={props.message.provided} correct={props.message.correct}
          />
        </div>
      }

      {props.allCorrectionMode && !props.showTranscriptMode &&
        <div>
          {props.message.corrections.map((correction, index) => {
              if (index != props.message.corrections.length - 1) {
                return (
                  <CorrectionText correctionText={correction.correction} correctionId={correction.id} correctionCorrect={correction.correct}
                    handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} showCheckbox={props.showCheckbox}
                    addCorrection={props.addCorrection} canAddCorrection={props.canAddCorrection} correctionSuggestion={''}
                    provided={props.message.provided} correct={props.message.correct}
                  />
                )
              } else {
                return (
                  <div>
                  <li ref={scrollRef1} />
                  <CorrectionText correctionText={correction.correction} correctionId={correction.id} correctionCorrect={correction.correct}
                    handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} showCheckbox={props.showCheckbox}
                    addCorrection={props.addCorrection} canAddCorrection={props.canAddCorrection} correctionSuggestion={props.correctionSuggestion}
                    provided={props.message.provided} correct={props.message.correct}
                  />
                  </div>
                )
              }
            })
          }
        </div>
      }

      {props.allCorrectionMode && props.showTranscriptMode &&
        <div>
          {props.message.corrections.map((correction, index) => {
            if (index != props.message.corrections.length - 1) {
              return (
                <div>
                  <CorrectionTranscript transcript={correction.transcript}
                    correctionId={correction.id} handle401Response={props.handle401Response}
                    showCheckbox={props.showCheckbox} showTranscript={props.showTranscript} userToMimic={props.userToMimic} datetime={correction.datetime}
                  />
                  <CorrectionText correctionText={correction.correction} correctionId={correction.id} correctionCorrect={correction.correct}
                    handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} showCheckbox={props.showCheckbox}
                    addCorrection={props.addCorrection} canAddCorrection={props.canAddCorrection} correctionSuggestion={''}
                    provided={props.message.provided} correct={props.message.correct}
                  />
                </div>
              )
            } else {
              return (
                <div>
                  <li ref={scrollRef1} />
                  <CorrectionTranscript transcript={correction.transcript}
                    correctionId={correction.id} handle401Response={props.handle401Response} autoplay={props.autoplayLatest}
                    showTranscript={props.showTranscript} userToMimic={props.userToMimic} datetime={correction.datetime}
                  />
                  <CorrectionText correctionText={correction.correction} correctionId={correction.id} correctionCorrect={correction.correct}
                    handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} showCheckbox={props.showCheckbox}
                    addCorrection={props.addCorrection} canAddCorrection={props.canAddCorrection} correctionSuggestion={props.correctionSuggestion}
                    provided={props.message.provided} correct={props.message.correct}
                  />
                </div>
              )
            }
            })
          }
        </div>
      }

    </div>
  );
}

// props: messageId, transcript, displayText, reference, messageCorrect, audioAvailable, provided, index, addCorrectionToChats,
// scrollTrigger, handle401Response, updateCorrectStateOfChats, searchAndUpdateSkippedStateOfChats, showCheckbox,
// showRecording, humanCorrector, skipped, userToMimic
function MessageText(props) {
  const [isUserRecording1, setIsUserRecording1] = useState(false);
  const [audioURL1, resetAudio, isRecording, startRecording1, stopRecording1, setAudioUrl, recordingStarted] = useRecorder();
  const [cursor1, setCursor1] = useState('default');

  useEffect(() => {
      document.body.style.cursor = cursor1;
    }, [cursor1]);

  useEffect(() => {
    if (audioURL1 != '') {
      const formdataMap = new Map([['message_id', props.messageId], ['human', props.humanCorrector]]);
      fetchData({serverFct: 'transcribe_and_correct', audioUrlToFetch: audioURL1, formdataMap: formdataMap,
        postprocessing: (text) => {
          props.addCorrectionToChats(text, props.index, props.provided);
          props.scrollTrigger();
        }, handle401Response: props.handle401Response
      });
    }
  }, [audioURL1]);

  const handleCorrection = async() => {
      if (isUserRecording1) {
        stopRecording1();
        setIsUserRecording1(false);
        setCursor1('default');
      } else {
        startRecording1();
        setIsUserRecording1(true);
        setCursor1('wait');
      }
  }

  const skipMessage = () => {
    const formdataMap = new Map([['message_id', props.messageId]]);
    fetchData({serverFct: 'skip_message', formdataMap: formdataMap,
      postprocessing: (text) => {
        props.searchAndUpdateSkippedStateOfChats(props.messageId, props.provided);
      },
      handle401Response: props.handle401Response
    });
  }

  return (
    <li className="text">
      <p className="p-text">
        {props.displayText !== null ? parse(props.displayText) : props.transcript}

        {props.displayText !== null && props.reference !== null &&
          <div>
            corrected should be:&nbsp;
            {props.reference}
          </div>
        }

        {props.audioAvailable &&
          <PlayAudioButton messageId={props.messageId} correctionId={-1} handle401Response={props.handle401Response}
            userToMimic={props.userToMimic}
          />
        }

        {props.showCheckbox &&
          <CorrectCheckBox correct={props.messageCorrect} messageId={props.messageId} correctionId={-1}
            handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} />
        }

        {(!props.provided || (!props.messageCorrect && !props.skipped)) && props.showRecording &&
          <Tooltip title="record correction (please wait with your speech until the microphone symbol was replaced by the stop symbol)">
            <IconButton onClick={handleCorrection} disabled={false} >
              {recordingStarted ? <Stop /> : <Mic />}
            </IconButton>
          </Tooltip>
        }

        {isUserRecording1 && !recordingStarted && "recording not ready"}

        {props.provided && props.messageCorrect ? <TaskAlt /> : props.skipped && <DisabledByDefault />}

        {props.provided && (!props.messageCorrect && !props.skipped) &&
          <Tooltip title="skip this message">
            <IconButton onClick={skipMessage}>
              <SkipNext />
            </IconButton>
          </Tooltip>
        }

      </p>
    </li>
  );
}

// props: transcript, correctionId, handle401Response, showTranscript, autoplay, userToMimic, datetime
function CorrectionTranscript(props) {
  return (
    <li className="transcript">
      <p className="p-transcript">
        {props.showTranscript &&
          props.transcript
        }
        <PlayAudioButton messageId={-1} correctionId={props.correctionId}
          handle401Response={props.handle401Response} autoplay={props.autoplay} userToMimic={props.userToMimic}
        />
        {props.userToMimic && props.datetime}
      </p>
    </li>
  );
}

// props: correctionText, correctionId, correctionCorrect, handle401Response, updateCorrectStateOfChats, showCheckbox,
// addCorrection, canAddCorrection, correctionSuggestion, provided, correct
function CorrectionText(props) {

  const buttonRef = useRef(null);
  const textAreaRef = useRef(null);

  const sendCorrection = () => {
    buttonRef.current.disabled = true;
    props.addCorrection(textAreaRef.current.value);
  };

  if (props.correctionText !== null) {
    return (
      <li className="correction">
        <p className="p-correction">
          {`corrected: ${props.correctionText} `}
          {props.provided && props.correct && <TaskAlt /> }
          {props.showCheckbox &&
            <CorrectCheckBox correct={props.correctionCorrect} messageId={-1} correctionId={props.correctionId}
              handle401Response={props.handle401Response} updateCorrectStateOfChats={props.updateCorrectStateOfChats} />
          }
        </p>
      </li>
    );
  } else if (props.correctionSuggestion) {
    return (
      <li className="correction">
        <p className="p-correction">
          <textarea id="correctionTextArea" className="correction-textarea" rows={4} defaultValue={props.correctionSuggestion} ref={textAreaRef} /> <br />
          <button onClick={sendCorrection} disabled={!props.canAddCorrection} ref={buttonRef} >Send correction</button>
        </p>
      </li>
    );
  }
}

// props: messageId, correctionId, handle401Response, autoplay, userToMimic
function PlayAudioButton(props) {

  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    if (props.autoplay) {
      console.log('autoplay');
      playAudio();
    }
  },[]);

  const playAudio = () => {
    const formats = new Map([['audio/ogg', 'ogg'], ['audio/webm', 'webm'], ['video/webm', 'webm'], ['audio/mp4', 'mp4'],
                            ['video/mp4', 'mp4'], ['audio/wave', 'wav'], ['audio/wav', 'wav'], ['audio/x-wav', 'wav']]);

    const audioElement = document.createElement('audio');

    let supportedFormats = new Set();
    formats.forEach((value, key) => {
      if (audioElement.canPlayType(key)) {
        supportedFormats.add(value);
      }
    });

    if (supportedFormats.length < 1) {
      supportedFormats.push('wav');
    }

    const supportedFormatsString = [...supportedFormats].join(',');

    const formdataMap = new Map([['message_id', props.messageId], ['correction_id', props.correctionId],
                                ['supported_formats', supportedFormatsString]]);
    addMimicUser(formdataMap, props.userToMimic);

    fetchData({serverFct: 'get_audio', formdataMap: formdataMap,
      postprocessing: (blob) => {
        if (!playing) {
          setPlaying(true);
          const audioUrlFromBlob = URL.createObjectURL(blob);
          const a = new Audio(audioUrlFromBlob);
          a.addEventListener('ended', () => setPlaying(false));
          a.play();
          setAudio(a);
        } else if (audio !== null) {
          audio.pause();
          audio.currentTime = 0;
          setPlaying(false);
        }
      },
      handle401Response: props.handle401Response
    });
  };

  return (
    <IconButton onClick={playAudio} disabled={false} >
      {playing ? <Stop/> : <PlayCircle/>}
    </IconButton>
  );
}

// props: correct, messageId, correctionId, handle401Response, updateCorrectStateOfChats
function CorrectCheckBox(props) {

  const [isCorrect, setIsCorrect] = useState(props.correct);

  const changeCorrect = () => {
    const formdataMap = new Map([['message_id', props.messageId], ['correction_id', props.correctionId], ['correct', !isCorrect]]);
    fetchData({serverFct: 'set_correct', formdataMap: formdataMap,
      postprocessing: (response) => {
        props.updateCorrectStateOfChats(response['correct'], props.messageId, props.correctionId);
        setIsCorrect(response['correct']);
      },
      handle401Response: props.handle401Response
    });
  }

  return (
    <Tooltip title="correct?">
      <IconButton onClick={changeCorrect} disabled={false} >
        {isCorrect ? <CheckBox /> : <CheckBoxOutlineBlank />}
      </IconButton>
    </Tooltip>
  );

}