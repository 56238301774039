import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, TextField } from '@mui/material';

import { withRouter } from '../common/with-router';
import AuthService from "../services/auth.service";
import fetchData from "../services/error-corrector-api"

function Profile() {
  const [redirect, setRedirect] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setRedirect("/login");
    }
    else {
      setCurrentUser(currentUser);
      setUserReady(true);
    }
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />
  }

  const onChangeOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const onChangeNewPassword1 = (event) => {
    setNewPassword1(event.target.value);
  };

  const onChangeNewPassword2 = (event) => {
    setNewPassword2(event.target.value);
  };

  const changePassword = () => {
    const formdataMap = new Map([['old_password', oldPassword], ['new_password_1', newPassword1], ['new_password_2', newPassword2]]);
    fetchData({serverFct: 'change_password', formdataMap: formdataMap,
      handle401Response: () => {}});
  };

  return (
    <div className="container">
      {userReady
      ?
        <div>
          <header className="jumbotron">
            <h3>
              <strong>{currentUser.user.email}</strong> Profile
            </h3>
          </header>
          <TextField
            type="password"
            id="OldPasswordField"
            label="current password"
            fullWidth
            value={oldPassword}
            onChange={onChangeOldPassword}
          />
          <TextField
            type="password"
            id="NewPasswort1Field"
            label="new password"
            fullWidth
            value={newPassword1}
            onChange={onChangeNewPassword1}
          />
          <TextField
            type="password"
            id="NewPasswort2Field"
            label="repeat new password"
            fullWidth
            value={newPassword2}
            onChange={onChangeNewPassword2}
          />
          <Button variant="contained" onClick={changePassword}>
            Change Password
          </Button>

        </div>
      : null}
    </div>
  );
}

export default withRouter(Profile);

