import authHeader from './auth-header';
import EventBus from "../common/EventBus";

import { SERVER_URL } from "../constants";

export function addMimicUser(formdataMap, mimicUser) {
  if (mimicUser != null)  {
    formdataMap.set('mimic_user', mimicUser);
  }
}

export async function fetchData({ serverFct = 'history', audioUrlToFetch = null, formdataMap = new Map(), postprocessing = () => {}, handle401Response = () => {} } = {}) {

  let formdata = new FormData();

  if (audioUrlToFetch != null) {
    let my_blob = await fetch(audioUrlToFetch).then(r => r.blob());
    const regex = /^audio\/([^;]+)/;
    const matched_regex = my_blob.type.match(regex);
    const extension = matched_regex.length == 2 ? matched_regex[1] : 'unk';
    formdata.append('audio_data', my_blob, `audio_data.${extension}`);
  }

  formdataMap.forEach((value, key) => {
    formdata.append(key, value);
  });

  let requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: formdata,
  };

  let response = await fetch(`${SERVER_URL}/${serverFct}/`, requestOptions);

  if (response.status==200) {
    if (serverFct == 'get_audio') {
      let blob = await response.blob();
      postprocessing(blob);
    } else {
      let text = await response.json();
      postprocessing(text);
    }
  } else if (response.status == 401) {
    EventBus.dispatch("logout");
    handle401Response();
  }
}

export default fetchData;