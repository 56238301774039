export class Message {
    constructor(id, text, displayText, reference, correct, skipped, audioAvailable, provided, corrections) {
        this.id = id;
        this.text = text;
        this.displayText = displayText;
        this.reference = reference;
        this.correct = correct;
        this.skipped = skipped;
        this.audioAvailable = audioAvailable;
        this.provided = provided;
        this.corrections = corrections;
    }
}

export class Correction {
    constructor(id, transcript, correction, correct, datetime) {
        this.id = id;
        this.transcript = transcript;
        this.correction = correction;
        this.correct = correct;
        this.datetime = datetime;
    }
}