const formatDuration = (duration) => {
    const date1 = new Date(0);
    date1.setSeconds(duration);
    return date1.toISOString().substring(11, 19);
};

export function emptyState() {
    return new Map([['numberSkippedMessages', 0], ['numberCorrectMessagesDone', 0], ['numberCorrectionsDone', 0],
        ['correctionAudioDurations', 0], ['numberCorrectMessagesDoneCorrector', 0],
        ['numberCorrectionsDoneCorrector', 0], ['correctionAudioDurationsCorrector', 0]]);
}

export function convertStatsToState(stats) {
    return new Map([
        ['numberSkippedMessages', stats['number_skipped_messages']],
        ['numberCorrectMessagesDone', stats['number_correct_messages_done']],
        ['numberCorrectionsDone', stats['number_corrections_done']],
        ['correctionAudioDurations', stats['correction_audio_durations']],
        ['numberCorrectMessagesDoneCorrector', stats['number_correct_messages_done_corrector']],
        ['numberCorrectionsDoneCorrector', stats['number_corrections_done_corrector']],
        ['correctionAudioDurationsCorrector', stats['number_corrections_done_corrector']]
    ]);
}

export function convertStateToString(state) {
    return `skipped messages: ${state.get('numberSkippedMessages')}, \
        correct messages done: ${state.get('numberCorrectMessagesDone')} \
        corrections done: ${state.get('numberCorrectionsDone')}, \
        correction audio durations: ${formatDuration(state.get('correctionAudioDurations'))} \
        correct messages done as verifier done: ${state.get('numberCorrectMessagesDoneCorrector')} \
        corrections done as verifier: ${state.get('numberCorrectionsDoneCorrector')}, \
        correction audio durations as verifier: ${formatDuration(state.get('correctionAudioDurationsCorrector'))}`;
}

// props: state
export function StatisticBox(props) {

    return (
        <div>
            skipped messages: {props.state.get('numberSkippedMessages')} <br />
            correct messages done: {props.state.get('numberCorrectMessagesDone')} <br />
            corrections done: {props.state.get('numberCorrectionsDone')} <br />
            correction audio durations: {formatDuration(props.state.get('correctionAudioDurations'))} <br />
            {props.state.get('numberCorrectionsDoneCorrector') > 0 &&
                <div>
                    correct messages done as verifier done: {props.state.get('numberCorrectMessagesDoneCorrector')} <br />
                    corrections done as verifier: {props.state.get('numberCorrectionsDoneCorrector')} <br />
                    correction audio durations as verifier: {formatDuration(props.state.get('correctionAudioDurationsCorrector'))} <br />
                </div>
            }
        </div>
    )

}