import axios from "axios";

import { SERVER_URL } from "../constants";

const API_URL = `${SERVER_URL}/api/auth/`;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "login/", {
        email,
        password
      })
      .then(response => {
        console.log(response.data.access);

        if (response.data.access) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(email, password, agreed_to_terms) {
    return axios.post(API_URL + "register/", {
      email,
      password,
      agreed_to_terms
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
