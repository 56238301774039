import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { withRouter } from '../common/with-router';
import AuthService from "../services/auth.service";
import fetchData from "../services/error-corrector-api"

import { SearchComponent } from './corrector.component';

function AdminComponent() {

  const [infos, setInfos] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const generateCsvFile = (infos) => {
    let fileData = 'user,supervisiors,number correct messages,number corrections,number correct messages and correction okay,number correct verified messages,number verified corrections,number correct verified messages and verified correction okay,quality\n'

    infos.map((user) => {
      let ratio1_okay = user['number_corrections_done'] > 0 ? user['number_correct_messages_done'] * 1.5 > user['number_corrections_done'] : true;
      let ratio2_okay = user['number_corrections_done_corrector'] > 0 ? user['number_correct_messages_done_corrector'] * 1.5 > user['number_corrections_done_corrector'] : true;
      let all_junior_supervisiors = `"${user['all_supervisior'].join(",")}"`
      fileData += `${user['user']},${all_junior_supervisiors},${user['number_correct_messages_done']},${user['number_corrections_done']},${ratio1_okay},${user['number_correct_messages_done_corrector']},${user['number_corrections_done_corrector']},${ratio2_okay}\n`
    });

    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'users.csv';
    link.href = url;
    link.click();
  };

  const handle401Response = () => {

  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setRedirect("/login");
    } else {
      fetchData({serverFct: 'get_admin_data',
      postprocessing: (text) => {
        setInfos(text);
      },
      handle401Response: handle401Response
      });
    }
  }, []);

  const addJunior = (mailAddress) => {
    fetchData({serverFct: 'add_junior_to_supervisor', formdataMap: new Map([['user', mailAddress]]),
      postprocessing: (text) => {
        setInfos(text);
      },
      handle401Response: handle401Response
    });
  };

  if (redirect) {
    return <Navigate to={redirect} />
  } else {
    return (
      <div>
        <SearchComponent addCorrection={addJunior} disabled={false} defaultText={''} />

        {infos.map((user) => {
          const date1 = new Date(0);
          date1.setSeconds(user['correction_audio_durations']);
          console.log(user['correction_audio_durations']);
          const correction_audio_durations = date1.toISOString().substring(11, 19);
          const date2 = new Date(0);
          date2.setSeconds(user['correction_audio_durations_corrector']);
          console.log(user['correction_audio_durations_corrector']);
          const correction_audio_durations_corrector = date2.toISOString().substring(11, 19);

          return (
            <div>
              <b>user: {user['user']}</b> <br />
              number of skipped messages: {user['number_skipped_messages']}<br />
              number of correct messages: {user['number_correct_messages_done']}<br />
              number of corrections: {user['number_corrections_done']}<br />
              audio durations: {correction_audio_durations}<br />
              <br />
              number of correct messages as verifier: {user['number_correct_messages_done_corrector']}<br />
              number of corrections as verifier: {user['number_corrections_done_corrector']}<br />
              audio durations as verifier: {correction_audio_durations_corrector}<br />
              <br />
            </div>
          )})
        }

        <button onClick={() => generateCsvFile(infos)}>export</button>
      </div>
    );
  }

}

export default withRouter(AdminComponent);